import http from '../../../helpers/http';
import * as types from './types';
import { setCurrentUser, fetchCurrentUser } from '../currentUser/actions';
import { toast } from 'react-toastify';

export const setLoading = (payload) => ({
  type: types.SET_LOADING,
  payload,
});

export const setError = (payload) => ({
  type: types.SET_ERROR,
  payload,
});

export const setAuthModal = (payload) => ({
  type: types.SET_AUTH_MODAL,
  payload,
});

export const loginUser = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const { data } = await http.post(`/auth/google`, payload);
    // dispatch(setAuthor({ author: data.author, authorPosts: data.authorPosts }));
    dispatch(setCurrentUser(data));
    dispatch(setLoading(false));
    setTimeout(() => fetchCurrentUser()(dispatch));
    return data;
  } catch (err) {
    // const { data } = err.response;
    // dispatch(setAuthor());
  }
};

export const loginUserWithEmail = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const { data } = await http.post(`/auth/login`, payload);
    // dispatch(setAuthor({ author: data.author, authorPosts: data.authorPosts }));
    dispatch(setCurrentUser(data));
    dispatch(setLoading(false));
    setTimeout(() => fetchCurrentUser()(dispatch));
    return data;
  } catch (err) {
    // const { data } = err.response;
    // dispatch(setAuthor());
  }
};

export const signupUser = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const { data } = await http.post(`/auth/signup`, payload);
    dispatch(setLoading(false));
    if (data.token) {
      dispatch(setCurrentUser(data));
      fetchCurrentUser()(dispatch);
    }
    toast.success(data.message);
    return data;
  } catch (err) {
    if (err?.response?.data) {
      return err.response.data;
    }
  }
};

export const verifyAccountEmail =
  ({ token }: { token?: string }) =>
  async (dispatch) => {
    try {
      const { data } = await http.get(`/auth/verify_email?token=${token}`);
      return data;
    } catch (err) {
      if (err?.response?.data) {
        return err.response.data;
      }
    }
  };
