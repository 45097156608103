import jsCookie from 'js-cookie';
import * as types from './types';
import initialState from './initialState';

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case types.SET_LOADING:
      return {
        ...state,
        categoryLoading: payload,
      };
    case types.SET_SUBMITTING:
      return {
        ...state,
        categorySubmitting: payload,
      };
    case types.SET_CATEGORIES:
      return {
        ...state,
        categoryLoading: false,
        categories: (payload || []).sort((a, b) => {
          if (a?.en < b?.en) {
            return -1;
          }
          if (a?.en > b?.en) {
            return 1;
          }
          return 0;
        }),
      };
    case types.SET_CATEGORY:
      return {
        ...state,
        categoryLoading: false,
        category: payload.category || {},
        categoryPosts: payload.categoryPosts || [],
        categoryFollowers: payload.categoryFollowers || [],
      };
    case types.UPDATE_LIKES_COUNT:
      return {
        ...state,
        category: {
          ...state.category,
          likesCount:
            state?.category?.slug === payload?.slug
              ? Number(state?.category?.likesCount || 0) + payload.count
              : state.category?.likesCount,
        },
        categories: state.categories.map((post) =>
          post.slug === payload.slug
            ? {
                ...post,
                likesCount: Number(post?.likesCount || 0) + payload.count,
              }
            : post,
        ),
      };
    case types.SET_CATEGORY_LIKES:
      return {
        ...state,
        categoryLikes: payload?.categoryLikes || [],
      };
    case types.ADD_CATEGORY_LIKE:
      return {
        ...state,
        categoryLikes: [payload, ...(state.categoryLikes || [])],
      };
    case types.REMOVE_CATEGORY_LIKE:
      return {
        ...state,
        categoryLikes: state.categoryLikes?.filter(
          (like) => like.userId !== payload.userId,
        ),
      };
    default:
      return state;
  }
}
