export const SET_LOADING = 'postComment/SET_LOADING';
export const SET_SUBMITTING = 'postComment/SET_SUBMITTING';
export const SET_ERROR = 'postComment/SET_ERROR';
export const SET_POST = 'postComment/SET_POST';
export const SET_COMMENT_MODAL = 'postComment/SET_COMMENT_MODAL';
export const SET_POST_COMMENTS = 'postComment/SET_POST_COMMENTS';
export const SET_POST_COMMENTS_LOADING =
  'postComment/SET_POST_COMMENTS_LOADING';
export const ADD_POST_COMMENT = 'postComment/ADD_POST_COMMENT';
export const ADD_POST_COMMENT_REPLY = 'postComment/ADD_POST_COMMENT_REPLY';
export const REMOVE_POST_COMMENT = 'postComment/REMOVE_POST_COMMENT';
export const REMOVE_POST_COMMENT_REPLY =
  'postComment/REMOVE_POST_COMMENT_REPLY';
