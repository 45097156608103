export const SET_LOADING = 'currentUser/SET_LOADING';
export const SET_SUBMITTING = 'currentUser/SET_SUBMITTING';
export const SET_ERROR = 'currentUser/SET_ERROR';
export const SET_CURRENT_USER = 'currentUser/SET_CURRENT_USER';
export const SET_IS_AUTH = 'currentUser/SET_IS_AUTH';
export const SET_CONFIRM_ACCOUNT_MODAL =
  'currentUser/SET_CONFIRM_ACCOUNT_MODAL';
export const SET_USER_PRODUCTS = 'currentUser/SET_USER_PRODUCTS';
export const ADD_BOOKMARK = 'currentUser/ADD_BOOKMARK';
export const REMOVE_BOOKMARK = 'currentUser/REMOVE_BOOKMARK';
export const ADD_LIKE = 'currentUser/ADD_LIKE';
export const REMOVE_LIKE = 'currentUser/REMOVE_LIKE';
export const ADD_CATEGORY_LIKE = 'currentUser/ADD_CATEGORY_LIKE';
export const REMOVE_CATEGORY_LIKE = 'currentUser/REMOVE_CATEGORY_LIKE';
export const FOLLOW_AUTHOR = 'currentUser/FOLLOW_AUTHOR';
export const UNFOLLOW_AUTHOR = 'currentUser/UNFOLLOW_AUTHOR';
export const SET_CURRENT_USER_CAN_POST =
  'currentUser/SET_CURRENT_USER_CAN_POST';
export const SET_CURRENT_USER_LOADED = 'currentUser/SET_CURRENT_USER_LOADED';
export const SET_CURRENT_USER_NOTIFICATIONS =
  'currentUser/SET_CURRENT_USER_NOTIFICATIONS';
