import * as types from './types';
import initialState from './initialState';

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case types.SET_LOADING:
      return {
        ...state,
        postLoading: payload,
      };
    case types.SET_SUBMITTING:
      return {
        ...state,
        postSubmitting: payload,
      };
    case types.SET_POST:
      return {
        ...state,
        postLoading: false,
        post: payload?.post || {},
        similarPosts: payload?.similarPosts || [],
      };
    case types.SET_FEED:
      return {
        ...state,
        postLoading: false,
        feed: payload,
      };
    case types.UPDATE_BOOKMARKS_COUNT:
      return {
        ...state,
        post: {
          ...state.post,
          bookmarksCount:
            state.post?.slug === payload.slug
              ? (state?.post?.bookmarksCount || 0) + payload.count
              : state.post.bookmarksCount,
        },
        feed: state.feed?.map((post) =>
          post.slug === payload.slug
            ? {
                ...post,
                bookmarksCount: post.bookmarksCount + payload.count,
              }
            : post,
        ),
      };
    case types.UPDATE_LIKES_COUNT:
      return {
        ...state,
        post: {
          ...state.post,
          likesCount:
            state?.post?.slug === payload?.slug
              ? (state?.post?.likesCount || 0) + payload.count
              : state.post.likesCount,
        },
        feed: state.feed?.map((post) =>
          post.slug === payload.slug
            ? {
                ...post,
                likesCount: post.likesCount + payload.count,
              }
            : post,
        ),
      };
    case types.UPDATE_DISLIKES_COUNT:
      return {
        ...state,
        post: {
          ...state.post,
          disLikesCount:
            state?.post?.slug === payload?.slug
              ? (state?.post?.disLikesCount || 0) + payload.count
              : state.post.disLikesCount,
        },
        feed: state.feed?.map((post) =>
          post.slug === payload.slug
            ? {
                ...post,
                disLikesCount: post.disLikesCount + payload.count,
              }
            : post,
        ),
      };
    case types.DELETE_POST:
      return {
        ...state,
        feed: state.feed.filter((post) => post.slug !== payload.slug),
      };
    default:
      return state;
  }
}
