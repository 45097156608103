import { createSelector } from 'reselect';

const selector = (state) => state.auth;

export const selectAuth = createSelector([selector], (state) => state);

export const selectAuthModal = createSelector(
  [selector],
  (state) => state.authModal,
);
