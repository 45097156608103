export const SET_LOADING = 'author/SET_LOADING';
export const SET_SUBMITTING = 'author/SET_SUBMITTING';
export const SET_ERROR = 'author/SET_ERROR';
export const SET_AUTHOR = 'author/SET_AUTHOR';
export const SET_AUTHORS = 'author/SET_AUTHORS';
export const SET_AUTHORS_POPULAR = 'author/SET_AUTHORS_POPULAR';
export const ADD_FOLLOWER = 'author/ADD_FOLLOWER';
export const REMOVE_FOLLOWER = 'author/REMOVE_FOLLOWER';
export const UPDATE_CURRENT_USER = 'author/UPDATE_CURRENT_USER';
export const DELETE_CURRENT_USER_POST = 'author/DELETE_CURRENT_USER_POST';
