import { roboto } from '@theme-ui/presets';

const FORM_BORDER_COLOR = 'rgba(0, 0, 0, 0.5)';

roboto.fontSizes[0] = 13;

// #171717
export const PRIMARY_COLOR = 'rgb(41, 41, 41)';
const theme = {
  ...roboto,
  fontWeights: {
    ...roboto.fontWeights,
    body: 300,
  },
  colors: {
    ...roboto.colors,
    text: '#171717',
    textBlack: 'black',
    textWhite: 'white',
    textRed: 'red',
    textGreen: '#1a8917',
    textGray: 'rgba(0, 0, 0, 0.5)',
    textGrayLight: 'rgba(0, 0, 0, 0.2)',
    primary: PRIMARY_COLOR,
    primaryLight: 'rgba(0, 0, 0, 0.5)',
    secondary: '#016177',
    secondaryLight: '#007fff0d',
    transparent: 'transparent',
    background: '##E5E5E5',
    backgroundGray: 'rgba(0, 0, 0, 0.3)',
    appGray: 'rgba(0,0, 0, 0.7)',
    modes: {
      dark: {
        text: '#171717',
        background: '#0d1117',
        primary: PRIMARY_COLOR,
        secondary: '#007fff33',
        secondaryLight: '#1b1f26',
        transparent: 'transparent',
      },
    },
  },
  // fontWeights: {
  //   body: 400,
  //   heading: '400 !important',
  //   bold: 600,
  // },
  containers: {
    page: {
      width: '100%',
      maxWidth: '1366px',
      m: 0,
      mx: 'auto',
      px: '5%',
    },
    main: {
      width: '100%',
      minHeight: `calc(100vh - 206px)`, // Minus Footer 455px Height
      pt: ['60px', '70px'],
    },
    box: {
      boxShadow: '0px 1px 1px 0 rgba(0,0,0,0.09)',
      borderRadius: 4,
    },
    ellipsis: {
      display: '-webkit-box',
      WebkitLineClamp: '3',
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  cards: {
    primary: {
      borderRadius: 9,
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
    },
  },
  styles: {
    ...roboto.styles,
    hr: {
      color: 'secondary',
    },
    a: {
      color: 'primary',
    },
  },
  messages: {
    error: {
      borderLeftColor: '#B00020',
      bg: '#b000203b',
    },
  },
  buttons: {
    primary: {
      color: 'white',
      bg: 'primary',
      '&:hover': {
        cursor: 'pointer',
        opacity: 0.8,
      },
      '&:disabled': {
        pointerEvents: 'all',
        cursor: 'not-allowed',
      },
      svg: {
        mr: 2,
      },
    },
    secondary: {
      color: 'background',
      bg: 'secondary',
      cursor: 'pointer',
      '&:disabled': {
        pointerEvents: 'all',
        cursor: 'not-allowed',
      },
      svg: {
        mr: 2,
      },
    },
    outline: {
      color: 'primary',
      bg: 'transparent',
      borderColor: 'primary',
      borderWidth: '1px',
      borderStyle: 'solid',
      cursor: 'pointer',
      '&:disabled': {
        pointerEvents: 'all',
        cursor: 'not-allowed',
      },
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      svg: {
        mr: 1,
        mt: '-1px',
      },
    },
    text: {
      bg: 'transparent',
      color: 'text',
      cursor: 'pointer',
    },
  },
  forms: {
    input: {
      borderColor: FORM_BORDER_COLOR,
    },
    textarea: {
      borderColor: FORM_BORDER_COLOR,
    },
    select: {
      borderColor: FORM_BORDER_COLOR,
      backgroundColor: 'white',
    },
  },
};

export default theme;
