import jsCookie from 'js-cookie';
import * as types from './types';
import initialState from './initialState';

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case types.SET_LOADING:
      return {
        ...state,
        chatLoading: payload,
      };
    case types.SET_SUBMITTING:
      return {
        ...state,
        chatSubmitting: payload,
      };
    case types.SET_CHATS:
      return {
        ...state,
        chatLoading: false,
        chats: payload,
      };
    case types.SET_CHAT:
      return {
        ...state,
        chatLoading: false,
        chat: payload.chat || {},
      };
    default:
      return state;
  }
}
