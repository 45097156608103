import React from 'react';
import Styled, { keyframes } from 'styled-components';

type Props = {
  sx?: unknown;
  variant?: string;
  className?: string;
  text?: string;
};

const spinnerAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const StyleSpinner = Styled.div`
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid #ffffff;
  transform: translateZ(0);
  animation: ${spinnerAnimation} 1.1s infinite linear;

  border-radius: 50%;
  width: 10em;
  height: 10em;
  &:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
  }
`;

const Wrapper = Styled.div`
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
`;
export const SpinnerCircle: React.FC<Props> = ({ text }) => {
  return (
    <Wrapper>
      <StyleSpinner>{text}</StyleSpinner>;
    </Wrapper>
  );
};

export default SpinnerCircle;
