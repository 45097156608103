export default {
  authorLoading: false,
  authorSubmitting: false,
  author: {},
  authorPosts: [],
  authors: [],
  authorFollowers: [],
  authorFollowersCount: 0,
  authorFollowingsCount: 0,
  authorFollowings: [],
};
