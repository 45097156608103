/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Button, Spinner } from 'theme-ui';
import React from 'react';

type Props = {
  loading?: boolean;
  onClick?: (e?: any) => void;
  sx?: unknown;
  variant?: string;
  disabled?: boolean;
  className?: string;
  type?: any;
};

const CustomButton: React.FC<Props> = ({
  children,
  disabled,
  loading,
  variant,
  onClick,
  ...props
}) => {
  return (
    <Button
      sx={{ position: 'relative', px: 4 } as any}
      {...props}
      disabled={disabled || loading}
      variant={variant}
      onClick={onClick}
    >
      {children}
      {loading && (
        <div
          sx={{
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            pointerEvents: 'none',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            bg:
              !variant || variant == 'secondary'
                ? 'rgba(0, 0, 0, 0.1)'
                : 'transparent',
          }}
        >
          <Spinner strokeWidth="2" size="25" color="rgb(170, 170, 170)" />
        </div>
      )}
    </Button>
  );
};

export default CustomButton;
