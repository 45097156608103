import * as types from './types';
import initialState from './initialState';

export default function author(state = initialState, { type, payload }) {
  switch (type) {
    case types.SET_LOADING:
      return {
        ...state,
        authorLoading: payload,
      };
    case types.SET_SUBMITTING:
      return {
        ...state,
        authorSubmitting: payload,
      };
    case types.SET_AUTHORS:
      return {
        ...state,
        authorLoading: false,
        authors: payload,
      };
    case types.SET_AUTHOR:
      return {
        ...state,
        authorLoading: false,
        author: payload.author,
        authorPosts: payload.authorPosts,
        authorFollowers: (payload.authorFollowers || []).map((item) => ({
          ...item,
          username: item?.user?.username,
          avatarUrl: item?.user?.avatarUrl,
        })),
        authorFollowersCount: payload?.authorFollowersCount || 0,
        authorFollowings: (payload.authorFollowings || []).map((item) => ({
          ...item,
          username: item?.user?.username,
          avatarUrl: item?.user?.avatarUrl,
        })),
        authorFollowingsCount: payload?.authorFollowingsCount || 0,
      };
    case types.DELETE_CURRENT_USER_POST:
      return {
        ...state,
        authorPosts: state.authorPosts.filter(
          (post) => post.slug !== payload.slug,
        ),
      };
    case types.UPDATE_CURRENT_USER:
      return {
        ...state,
        author: {
          ...state.author,
          ...payload,
        },
      };
    case types.ADD_FOLLOWER:
      return {
        ...state,
        authorFollowers: [payload, ...state.authorFollowers],
        authorFollowersCount: payload?.authorFollowersCount + 1,
      };
    case types.REMOVE_FOLLOWER:
      return {
        ...state,
        authorFollowers: [
          ...state.authorFollowers.filter(
            (item) => item.userId !== payload.userId,
          ),
        ],
        authorFollowersCount: payload?.authorFollowersCount - 1,
      };
    case types.SET_AUTHORS_POPULAR:
      return {
        ...state,
        authorLoading: false,
        author: payload,
      };
    default:
      return state;
  }
}
