export default {
  Home: 'Home',
  Language: 'Language',
  Login: 'Login',
  Signup: 'Signup',
  'Log in': 'Log in',
  'Log out': 'Log out',
  'Sign up': 'Sign up',
  SiteDescription: 'Stay informed about what happening around you',
  home_headline: 'Our dialects beyond borders',
  home_into: `For a linguistic variety without a demographic or a geographic limitation,
              Lalect allows you to share the language of your community and
              learn more about other dialects in other communities around you`,
  home_footline: 'Make the world know about your dialect',
  footer_copyright: 'Copyright © {{year}} NoticeFax',
  Search: 'Search',
  en: 'English',
  fr: 'French',
  Reply: 'Reply',
  Delete: 'Delete',
  Like: 'Like',
  Likes: 'Likes',
  View: 'View',
  Views: 'Views',
  'Login with Google': 'Login with Google',
  'Signup with Google': 'Signup with Google',
  'Login failed, please try again': 'Login failed, please try again',
  Profile: 'Profile',
  Settings: 'Settings',
  Logout: 'Logout',
  'Did you know?': 'Did you know?',
  'Welcome Back!': 'Welcome Back!',
  'Welcome!': 'Welcome',
  'View More Languages': 'View More Languages',
  'Most Viewed': 'Most Viewed',
  Country: 'Country',
  Province: 'Province',
  Region: 'Region',
  Description: 'Description',
  Next: 'Next',
  Previous: 'Previous',
  'Create A Post': 'Create A Post',
  Categories: 'Categories',
  Contact: 'Contact',
  About: 'About',
  FAQ: 'FAQ',
  Support: 'Support',
  'Terms & Privacy': 'Terms & Privacy',
  'See All': 'See All',
  'Popular Authors': 'Popular Authors',
  'Personal Info': 'Personal Info',
  Bio: 'Bio',
  Gender: 'Gender',
  'Birth Date': 'Birth Date',
  Location: 'Location',
  Email: 'Email',
  Occupation: 'Occupation',
  Upload: 'Upload',
  'You are not allowed to post': 'You are not allowed to post',
  Post: 'Post',
  Save: 'Save',
  'All Categories': 'All Categories',
  'Register as an author': 'Register as an author',
  Password: 'Password',
  'Confirm Password': 'Confirm Password',
  'Forgot Password?': 'Forgot Password?',
  Or: 'Or',
  'Already have an account?': 'Already have an account?',
  'First Name': 'First Name',
  'Last Name': 'Last Name',
  "Post isn't liked": "Post isn't liked",
  'Post unliked successfuly': 'Post unliked successfuly',
  Edit: 'Edit',
  Male: 'Male',
  Female: 'Female',
  'Twitter Link': 'Twitter Link',
  'Facebook Link': 'Facebook Link',
  'LinkedIn Link': 'LinkedIn Link',
  'Instagram Link': 'Instagram Link',
  'Edit Social media accounts': 'Edit Social media accounts',
  'Edit Bio': 'Edit Bio',
  Notifications: 'Notifications',
  'All Notifications': 'All Notifications',
  'Followers Notifications': 'Followers Notifications',
  'New Article Notifications': 'New Article Notifications',
  "Article's Comment Notifications": "Article's Comment Notifications",
  "Article's Like Notifications": "Article's Like Notifications",
  "Article's Reply Notifications": "Article's Reply Notifications",
  'Read more': 'Read more',
  'Author Application': 'Author Application',
  Reason: 'Reason',
  'Your application will be worked on within 7 working days after subscription':
    'Your application will be worked on within 7 working days after subscription',
  Apply: 'Apply',
  'NoticeFax is a Pan-African online platform for localized and customized information sharing':
    'NoticeFax is a Pan-African online platform for localized and customized information sharing',
  'Signup Submitted': 'Signup Submitted',
  'Check your email inbox or spam to verify your account':
    'Check your email inbox or spam to verify your account',
  'Privacy Policy': 'Privacy Policy',
  'Terms & Conditions': 'Terms & Conditions',
  'Terms of Service': 'Terms of Service',
  Type: 'Type',
  Place: 'Place',
  Event: 'Event',
  'Select category': 'Select category',
  'Page Not Found': 'Page Not Found',
  "We're sorry the page you requested could not be found. Please go back home":
    "We're sorry the page you requested could not be found. Please go back home",
  'Go to home': 'Go to home',
  'Save Draft': 'Save Draft',
  Publish: 'Publish',
  'Submit Post': 'Submit Post',
  'Cover Image': 'Cover Image',
  General: 'General',
  'By clicking Sign up, you agree to our Terms of Service and Privacy Policy': `By clicking Sign up, you agree to our
    <a  href="{{serviceLink}}" target="_blank" rel="noopener noreferrer"><strong>Terms of Service</strong></a> and
    <a  href="{{privacyLink}}" target="_blank" rel="noopener noreferrer"><strong>Privacy Policy</strong></a>`,
  'You need to agree to our Terms of Service and Pricacy':
    'You need to agree to our Terms of Service and Pricacy',
  Summary: 'Summary',
  Joined: 'Joined',
  'Total Posts': 'Total Posts',
  'Published Posts': 'Published Posts',
  Followed: 'Followed',
  Follow: 'Follow',
  'No posts yet': 'No posts yet',
  Yes: 'Yes',
  Cancel: 'Cancel',
  'Your thoughts?': 'Your thoughts?',
  Comment: 'Comment',
  Address: 'Address',
  Tags: 'Tags',
  Add: 'Add',
  'Add a tag': 'Add a tag',
  'Add up to 5 tags to describe your article':
    'Add up to 5 tags to describe your article',
  '{{postsCount}} Post(s)': '{{postsCount}} Post(s)',
  'Please login first to continue': 'Please login first to continue',
  'You can only upload an image of less or equal to 2 Mb': 'You can only upload an image of less or equal to 1.5 Mb'
};
