import jsCookie from 'js-cookie';
import * as types from './types';
import initialState from './initialState';

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case types.SET_LOADING:
      return {
        ...state,
        currentUserLoading: payload,
      };
    case types.SET_SUBMITTING:
      return {
        ...state,
        currentUserSubmitting: payload,
      };
    case types.SET_IS_AUTH:
      return {
        ...state,
        isAuth: payload,
      };
    case types.SET_CONFIRM_ACCOUNT_MODAL:
      return {
        ...state,
        confirmAccountModal: payload,
      };
    case types.SET_CURRENT_USER_LOADED:
      return {
        ...state,
        currentUserLoaded: payload,
      };
    case types.SET_CURRENT_USER:
      jsCookie.set('_nf_user', JSON.stringify(payload?.user || {}));

      if (payload?.token !== undefined) {
        jsCookie.set('_nf_token', payload?.token || '');
        if (process.browser) {
          localStorage.setItem('_nf_token', payload?.token);
        }
      }

      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          ...(payload?.user || {}),
        },
        currentUserLoading: false,
        isAuth: !!payload?.user?.firstName,
        token: payload?.token,
        bookmarks: payload?.bookmarks || [],
        followers: payload?.followers || [],
        likes: (payload?.likes || []).filter((item) => item.status == 'like'),
        categoriesLikes: payload?.categoriesLikes || [],
        disLikes: (payload?.likes || []).filter(
          (item) => item.status === 'dislike',
        ),
        notifications: payload?.notifications || [],
      };
    case types.SET_CURRENT_USER_NOTIFICATIONS:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          ...(payload?.user || {}),
        },
        currentUserLoading: false,
        notifications: payload?.notifications || [],
      };
    case types.SET_CURRENT_USER_CAN_POST:
      jsCookie.set('_nf_canPost', payload);
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          canPost: payload,
        },
      };
    case types.SET_USER_PRODUCTS:
      return {
        ...state,
        userProducts: payload,
      };
    case types.ADD_BOOKMARK:
      return {
        ...state,
        bookmarks: [...state.bookmarks, payload],
      };
    case types.REMOVE_BOOKMARK:
      return {
        ...state,
        bookmarks: state.bookmarks.filter(
          (item) => item?.postId !== payload?.postId,
        ),
      };
    case types.ADD_LIKE:
      return {
        ...state,
        likes: [...state.likes, payload],
      };
    case types.REMOVE_LIKE:
      return {
        ...state,
        likes: state.likes.filter((item) => item?.postId !== payload?.postId),
      };
    case types.ADD_CATEGORY_LIKE:
      return {
        ...state,
        categoriesLikes: [...state.categoriesLikes, payload],
      };
    case types.REMOVE_CATEGORY_LIKE:
      return {
        ...state,
        categoriesLikes: state.categoriesLikes.filter(
          (item) => item?.categoryId !== payload?.categoryId,
        ),
      };
    case types.FOLLOW_AUTHOR:
      return {
        ...state,
        followers: [...state.followers, payload],
      };
    case types.UNFOLLOW_AUTHOR:
      return {
        ...state,
        followers: state.followers.filter(
          (item) => item.followedId !== payload?.followedId,
        ),
      };
    default:
      return state;
  }
}
