import * as types from './types';
import initialState from './initialState';

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case types.SET_LOADING:
      return {
        ...state,
        authLoading: payload,
      };
    case types.SET_SUBMITTING:
      return {
        ...state,
        authSubmitting: payload,
      };
    case types.SET_AUTH_MODAL:
      return {
        ...state,
        authModal: payload,
      };
    default:
      return state;
  }
}
