import * as types from './types';
import initialState from './initialState';

export default function authorPopular(state = initialState, { type, payload }) {
  switch (type) {
    case types.SET_LOADING:
      return {
        ...state,
        authorPopularLoading: payload,
      };
    case types.SET_SUBMITTING:
      return {
        ...state,
        authorPopularSubmitting: payload,
      };
    case types.SET_AUTHORS:
      return {
        ...state,
        authorPopularLoading: false,
        authorsPopular: payload,
      };
    case types.SET_AUTHORS_POPULAR:
      return {
        ...state,
        authorPopularLoading: false,
        authorsPopular: payload,
      };
    default:
      return state;
  }
}
