/** @jsxRuntime classic */
/** @jsx jsx */
import { useEffect } from 'react';
import { jsx, Button } from 'theme-ui';
import ReactModal from 'react-modal';
import { MdClose } from 'react-icons/md';

const styles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '450px',
    maxWidth: '90%',
    position: 'relative',
  },
  overlay: {
    zIndex: 1000,
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
  },
};

ReactModal.setAppElement('#__next');

export interface Props {
  onClose: () => void;
  title?: string;
  children?: any;
  open: boolean;
  contentLabel?: string;
  width?: string;
}

export default function Modal({
  onClose,
  title,
  children,
  open,
  contentLabel,
  width = '450px',
}: Props) {
  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [open]);

  return (
    <ReactModal
      isOpen={!!open}
      onRequestClose={onClose}
      contentLabel={contentLabel}
      style={{
        ...styles,
        content: {
          ...styles.content,
          width,
        },
      }}
    >
      <Button
        sx={{
          position: 'absolute',
          top: '15px',
          right: '0px',
        }}
        variant="text"
        onClick={onClose}
      >
        <MdClose size="1.2rem" />
      </Button>
      <h2 sx={{ mt: 0, fontWeight: 400 }}>{title}</h2>
      {children}
    </ReactModal>
  );
}
