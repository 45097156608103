/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { useEffect } from 'react';
import Router, { useRouter } from 'next/router';
import { ThemeProvider } from 'theme-ui';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'nprogress/nprogress.css';
import 'react-loading-skeleton/dist/skeleton.css';

import NProgress from 'nprogress';
import '../styles/index.scss';
import 'react-toastify/dist/ReactToastify.css';

import theme from '../theme';
import store from '../redux/store';
import locales from '../locales';
import AuthModal from '../components/AuthModal';
// import AddLanguageModal from '../components/AddLanguageModal';
import cookieParser from '../helpers/cookieParser';

//Binding events.
Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

App.getInitialProps = async ({ ctx, Component }) => {
  const cookies = cookieParser(ctx.req);
  let pageProps = {};
  if (Component.getInitialProps) {
    pageProps = await Component.getInitialProps(ctx);
  }

  return {
    pageProps: {
      ...pageProps,
      cookies,
    },
  };
};

export default function App({ Component, pageProps }) {
  const { locale } = useRouter();

  useEffect(() => {
    locales.changeLanguage(locale);
  }, [locale]);

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <AuthModal />
        <ToastContainer />
        {/* <AddLanguageModal /> */}
        <Component {...pageProps} />
      </ThemeProvider>
    </Provider>
  );
}
