export default {
  Home: 'Accueil',
  Language: 'Langue',
  Login: 'Connexion',
  Signup: 'Inscription',
  'Log in': 'Connexion',
  'Log out': 'Déconnexion',
  'Sign up': 'Inscription',
  SiteDescription: 'Stay informed about what happening around you',
  home_headline: 'Vos dialectes au-delà des frontières',
  home_into: `Pour une variété linguistique sans limitation démographique ou géographique,
               Lalect vous permet de partager la langue de votre communauté et
               en savoir plus sur les autres dialectes des autres communautés autour de vous`,
  home_footline: 'Faites connaître votre dialecte au monde entier',
  footer_copyright: 'Copyright © {{year}} NoticeFax',
  Search: 'Recherche',
  'Search for ...': 'Rechercher pour ...',
  Phrases: 'Phrases',
  Phrase: 'Phrase',
  en: 'Angalis',
  fr: 'Français',
  Reply: 'Reply',
  Like: 'Aime',
  Likes: 'Aimes',
  View: 'Vue',
  Views: 'Vues',
  'Login with Google': 'Connectez-vous avec Google',
  'Signup with Google': 'Signup with Google',
  'Login failed, please try again': 'Échec de la connexion, veuillez réessayer',
  Profile: 'Profil',
  Settings: 'Paramètres du compte',
  Logout: 'Se déconnecter',
  'Did you know?': 'Le saviez-vous?',
  'Welcome Back!': 'Content de te revoir!',
  'Welcome!': 'Bienvenu(e)!',
  'Most Viewed': 'Les plus consultés',
  Country: 'Pays',
  Province: 'Province',
  Region: 'Region',
  Description: 'Description',
  Next: 'Next',
  Previous: 'Previous',
  'Create A Post': 'Creer Un Poste',
  Categories: 'Categories',
  Contact: 'Contact',
  About: 'A Propos',
  FAQ: 'FAQ',
  Support: 'Support',
  'Terms & Privacy': 'Terms & Privacy',
  'See All': 'Voir Tout',
  'Popular Authors': 'Auteurs Populaires',
  'Personal Info': 'Personal Info',
  Bio: 'Bio',
  Gender: 'Sexe',
  'Birth Date': 'Date de Naissance',
  Location: 'Location',
  Email: 'Email',
  Occupation: 'Occupation',
  Upload: 'Telecharger',
  'You are not allowed to post': 'You are not allowed to post',
  Post: 'Poster',
  Save: 'Save',
  'All Categories': 'All Categories',
  'Register as an author': 'Register as an author',
  Password: 'Mode de passe',
  'Confirm Password': 'Confirm Password',
  'Forgot Password?': 'Mot de passe oublie?',
  Or: 'Or',
  'Already have an account?': 'Already have an account?',
  'First Name': 'First Name',
  'Last Name': 'Last Name',
  "Post isn't liked": "Post isn't liked",
  'Post unliked successfuly': 'Post unliked successfuly',
  'Edit Bio': 'Edit Bio',
  'Author Application': 'Author Application',
  Reason: 'Reason',
  'Your application will be worked on within 7 working days after subscription':
    'Your application will be worked on within 7 working days after subscription',
  Apply: 'Apply',
  'NoticeFax is a Pan-African online platform for localized and customized information sharing':
    'NoticeFax is a Pan-African online platform for localized and customized information sharing',
  'Signup Submitted': 'Inscription soumise',
  'Check your email inbox or spam to verify your account':
    'Vérifiez votre boîte de réception ou vos spams pour vérifier votre compte',
  'Privacy Policy': 'Politique de confidentialité',
  'Terms & Conditions': 'Conditions générales',
  'Terms of Service': "Conditions d'utilisation",
  Type: 'Type',
  Place: 'Place',
  Event: 'Event',
  'Select category': 'Choisir une catégorie',
  'Page Not Found': 'Page introuvable',
  "We're sorry the page you requested could not be found. Please go back home":
    'Nous sommes désolés que la page que vous avez demandée soit introuvable. Veuillez retourner à la maison',
  'Go to home': "Aller à l'accueil",
  'Save Draft': 'Enregistrer le brouillon',
  Publish: 'Publier',
  'Submit Post': 'Soumettre le post',
  'Cover Image': 'Image de couverture',
  General: 'Général',
  'By clicking Sign up, you agree to our Terms of Service and Privacy Policy': `By clicking Sign up, you agree to our
    <a  href="{{serviceLink}}" target="_blank" rel="noopener noreferrer"><strong>Terms of Service</strong></a> and
    <a  href="{{privacyLink}}" target="_blank" rel="noopener noreferrer"><strong>Privacy Policy</strong></a>`,
  Summary: 'Résumé',
  Joined: 'Inscrit',
  'Total Posts': 'Nombre total des articles',
  'Published Posts': 'Articles publiés',
  Followed: 'Suivi',
  Follow: 'Suis',
  'No posts yet': "Pas encore d'artciles",
  Yes: 'Oui',
  Cancel: 'Annuler',
  'Your thoughts?': 'Vos pensées ?',
  Comment: 'Commenter',
  Address: 'Adresse',
  Tags: 'Mots clés',
  Add: 'Ajouter',
  'Add a tag': 'Ajouter un tag',
  'Add up to 5 tags to describe your article':
    "Ajoutez jusqu'à 5 tags pour décrire votre article",
  '{{postsCount}} Post(s)': '{{postsCount}} Article(s)',
  'Please login first to continue':
    "Veuillez vous connecter d'abord pour continuer",
    'You can only upload an image of less or equal to 2 Mb': "Vous ne pouvez télécharger qu'une image de moins de 2 Mo"
};
