import jsCookie from 'js-cookie';

let token = jsCookie.get('_nf_token') || '';

const currentUser = JSON.parse(jsCookie.get('_nf_user') || '{}');

if (process.browser && !token) {
  token = localStorage.getItem('_nf_token');
}

export default {
  currentUserLoading: false,
  currentUserSubmitting: false,
  isAuth: !!token,
  currentUserLoaded: false,
  currentUser: {
    ...currentUser,
  },
  bookmarks: [],
  likes: [],
  disLikes: [],
  comments: [],
  followers: [],
  notifications: [],
  categoriesLikes: [],
};
