export default {
  postLoading: false,
  postSubmitting: false,
  post: {
    slug: null,
    bookmarksCount: 0,
    likesCount: 0,
    commentsCount: 0,
    disLikesCount: 0,
  },
  feed: [],
  similarPosts: [],
};
