import * as types from './types';
import initialState from './initialState';

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case types.SET_LOADING:
      return {
        ...state,
        postCommentLoading: payload,
      };
    case types.SET_SUBMITTING:
      return {
        ...state,
        postCommentSubmitting: payload,
      };
    case types.SET_COMMENT_MODAL:
      return {
        ...state,
        postCommentModal: payload,
      };
    case types.SET_POST_COMMENTS:
      return {
        ...state,
        postCommentLoading: false,
        postComments: payload || [],
      };
    case types.ADD_POST_COMMENT:
      return {
        ...state,
        postComments: [payload, ...state.postComments],
      };
    case types.ADD_POST_COMMENT_REPLY:
      return {
        ...state,
        postComments: state.postComments.map((comment) => {
          if (comment.id === payload.parentId) {
            return {
              ...comment,
              replies: [...(comment.replies || []), payload],
            };
          }
          return comment;
        }),
      };
    case types.REMOVE_POST_COMMENT:
      return {
        ...state,
        postComments: [
          ...state.postComments.filter((comment) => {
            if (payload.parentId) {
              if (comment.id === payload.parentId) {
                console.log(
                  'payload',
                  payload,
                  'comment',
                  comment.replies.filter((reply) => reply.id !== payload.id),
                );
                return {
                  ...comment,
                  replies: [
                    ...comment.replies.filter(
                      (reply) => reply.id !== payload.id,
                    ),
                  ],
                };
              }
            } else {
              return comment.id !== payload.id;
            }
          }),
        ],
      };
    case types.REMOVE_POST_COMMENT_REPLY:
      return {
        ...state,
        postComments: [
          ...state.postComments.map((comment) => {
            if (comment.id === payload.parentId) {
              console.log(
                'payload',
                payload,
                'comment',
                comment.replies.filter((reply) => reply.id !== payload.id),
              );
              return {
                ...comment,
                replies: [
                  ...comment.replies.filter((reply) => reply.id !== payload.id),
                ],
              };
            }
            return comment;
          }),
        ],
      };
    default:
      return state;
  }
}
