/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { useTranslation } from 'react-i18next';
import TickSuccess from '../../assets/svg/tick-success.svg';

export const SignupSubmitted = () => {
  const { t } = useTranslation();
  return (
    <div
      sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      <img
        sx={{ height: ['100px', '150px'], mb: 4, mt: 2 }}
        src={TickSuccess}
        alt="tick success"
      />

      <p sx={{ textAlign: 'center' }}>
        {t('Check your email inbox or spam to verify your account')}
      </p>
    </div>
  );
};

export default SignupSubmitted;
