import axios from 'axios';
import jsCookie from 'js-cookie';
import { toast } from 'react-toastify';

const http = axios.create({
  baseURL: `${process.env.NEXT_PUBLIC_BASE_API_URL}`,
  headers: {
    'Access-Control-Allow-Origin': '*',
  },
});

const requestHandler = (request) => {
  if (!request.headers['X-Token'] && process.browser) {
    request.headers['X-Token'] = localStorage.getItem('_nf_token') || '';
  }

  if (!request.headers['X-Token'] && process.browser) {
    request.headers['X-Token'] = jsCookie.get('_nf_token') || '';
  }

  request.headers['X-Language'] = 'en';

  return request;
};

const errorHandler = (err) => {
  const { statusText } = err.response;
  const { message } = err.response.data;

  if (process.browser && ![404].includes(err.response.status)) {
    toast.error(message || statusText);
  }

  return Promise.reject({
    ...err,
    error: true,
    message: message || statusText,
    data: {
      ...err.response,
      message: message || statusText,
    },
  });
};

export const successHandler = (response) => response;

http.interceptors.request.use((request) => requestHandler(request));

http.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error),
);

export default http;
