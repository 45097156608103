/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Input, ThemeUIStyleObject } from 'theme-ui';
import React, { useState } from 'react';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';

type Props = {
  loading?: boolean;
  sx?: ThemeUIStyleObject;
  disabled?: boolean;
  className?: string;
  name?: string;
  onChange?: (a: any) => void;
  autocomplete?: string;
};

const PasswordInput: React.FC<Props> = ({ disabled, loading, ...props }) => {
  const [show, setShow] = useState(false);

  const toggle = () => {
    setShow(!show);
  };
  return (
    <div sx={{ position: 'relative', display: 'flex' }}>
      <Input
        type={show ? 'text' : 'password'}
        sx={
          {
            pr: '40px',
            width: '100%',
          } as any
        }
        {...props}
        disabled={disabled || loading}
      />
      <div
        sx={{
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          top: 0,
          right: 0,
          bottom: 0,
          width: '40px',
          m: '1px',
          cursor: 'pointer',
          borderRadius: 3,
        }}
        onClick={toggle}
      >
        {show ? (
          <AiOutlineEye onClick={toggle} />
        ) : (
          <AiOutlineEyeInvisible onClick={toggle} />
        )}
      </div>
    </div>
  );
};

export default PasswordInput;
