import http from '../../../helpers/http';
import * as types from './types';
import { addFollowAuthor, removeFollowAuthor } from '../currentUser/actions';

export const setLoading = (payload) => ({
  type: types.SET_LOADING,
  payload,
});

export const setError = (payload) => ({
  type: types.SET_ERROR,
  payload,
});

export const setAuthor = (
  payload = {
    author: {},
    authorPosts: [],
    authorFollowers: [],
    authorFollowersCount: 0,
    authorFollowings: [],
    authorFollowingsCount: 0,
  },
) => ({
  type: types.SET_AUTHOR,
  payload,
});

export const setAuthors = (payload) => ({
  type: types.SET_AUTHORS,
  payload,
});

export const addFollower = (payload) => ({
  type: types.ADD_FOLLOWER,
  payload,
});

export const removeFollower = (payload) => ({
  type: types.REMOVE_FOLLOWER,
  payload,
});

export const updateCurrentUser = (payload) => ({
  type: types.UPDATE_CURRENT_USER,
  payload,
});

export const deleteCurrentPost = (payload) => ({
  type: types.DELETE_CURRENT_USER_POST,
  payload,
});

export const fetchAuthors = () => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const { data } = await http.get('/authors');

    dispatch(setAuthors(data.authors));
  } catch (err) {
    // const { data } = err.response;
    dispatch(setAuthors([]));
  }
};

export const fetchAuthor =
  ({ username = '' }: { username?: string }) =>
  async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const { data } = await http.get(`/authors/${username}`);
      dispatch(
        setAuthor({
          author: data.author,
          authorPosts: data.authorPosts,
          authorFollowersCount: data.authorFollowersCount || 0,
          authorFollowers: data?.authorFollowers,
          authorFollowings: data?.authorFollowings,
          authorFollowingsCount: data?.authorFollowingsCount || 0,
        }),
      );
      return data;
    } catch (err) {
      // const { data } = err.response;
      dispatch(setAuthor());
    }
  };

export const followAuthor =
  ({
    username = '',
    followed = false,
    payload,
  }: {
    username?: string;
    followed?: boolean;
    payload: any;
  }) =>
  async (dispatch) => {
    try {
      if (followed) {
        dispatch(removeFollowAuthor(payload));
        dispatch(removeFollower(payload));
      } else {
        dispatch(addFollowAuthor(payload));
        dispatch(addFollower(payload));
      }
      const { data } = await http[`${followed ? 'delete' : 'post'}`](
        `/authors/${username}/followers`,
      );

      return data;
    } catch (err) {
      // const { data } = err.response;
      if (!followed) {
        dispatch(removeFollowAuthor(payload));
        dispatch(removeFollower(payload));
      } else {
        dispatch(addFollowAuthor(payload));
        dispatch(addFollower(payload));
      }
    }
  };
