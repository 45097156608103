import jsCookie from 'js-cookie';
import http from '../../../helpers/http';
import * as types from './types';
import { toast } from 'react-toastify';
import { updateCurrentUser } from '../author/actions';

export const setLoading = (payload) => ({
  type: types.SET_LOADING,
  payload,
});

export const setError = (payload) => ({
  type: types.SET_ERROR,
  payload,
});

export const setCurrentUser = (payload) => ({
  type: types.SET_CURRENT_USER,
  payload,
});

export const setCurrentNotifications = (payload) => ({
  type: types.SET_CURRENT_USER_NOTIFICATIONS,
  payload,
});

export const setCurrentUserLoaded = (payload) => ({
  type: types.SET_CURRENT_USER_LOADED,
  payload,
});

export const setCurrentUserCanPost = (payload) => ({
  type: types.SET_CURRENT_USER_CAN_POST,
  payload,
});

export const setIsAuth = (payload) => ({
  type: types.SET_IS_AUTH,
  payload,
});

export const setUserProducts = (payload) => ({
  type: types.SET_USER_PRODUCTS,
  payload,
});

export const addBookmark = (payload) => ({
  type: types.ADD_BOOKMARK,
  payload,
});

export const removeBookmark = (payload) => ({
  type: types.REMOVE_BOOKMARK,
  payload,
});

export const addLike = (payload) => ({
  type: types.ADD_LIKE,
  payload,
});

export const removeLike = (payload) => ({
  type: types.REMOVE_LIKE,
  payload,
});

export const addCategoryLike = (payload) => ({
  type: types.ADD_CATEGORY_LIKE,
  payload,
});

export const removeCategoryLike = (payload) => ({
  type: types.REMOVE_CATEGORY_LIKE,
  payload,
});

export const addFollowAuthor = (payload) => ({
  type: types.FOLLOW_AUTHOR,
  payload,
});

export const removeFollowAuthor = (payload) => ({
  type: types.UNFOLLOW_AUTHOR,
  payload,
});

export const fetchCurrentUser = () => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const { data } = await http.get('/user');
    dispatch(setIsAuth(true));
    dispatch(setCurrentUser(data));
    dispatch(setCurrentUserLoaded(true));
  } catch (err) {
    // const { data } = err.response;
    dispatch(setIsAuth(false));
    dispatch(setCurrentUser({}));
    dispatch(setCurrentUserLoaded(false));
    if (process.browser) {
      localStorage.setItem('_nf_token', '');
      jsCookie.set('_nf_token', '');
    }
  }
};

export const fetchCurrentUserNotifications = () => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const { data } = await http.get('/user/notifications');
    dispatch(setCurrentNotifications(data));
    return data;
  } catch (err) {
    // const { data } = err.response;
    dispatch(setLoading(true));
  }
};

export const updateUser = (payload) => async (dispatch) => {
  try {
    const { data } = await http.put('/user', payload);
    dispatch(setCurrentUser(data));
    dispatch(updateCurrentUser(data.user));
    toast.success(data.message);
    return data;
  } catch (err) {}
};

export const authorApplication = (payload) => async (dispatch) => {
  try {
    const { data } = await http.post('/applications', payload);
    dispatch(setCurrentUserCanPost(data?.user?.canPost));
    toast.success(data.message);
    return data;
  } catch (err) {}
};

export const updateUserImage =
  ({ type, payload }: { type: 'avatar' | 'cover'; payload: any }) =>
  async (dispatch) => {
    try {
      const { data } = await http.put(`/user/${type}`, payload);
      dispatch(setCurrentUser(data));
      dispatch(updateCurrentUser(data.user));
      // toast.success(data.message);
      return data;
    } catch (err) {}
  };

export const logoutUser = () => async (dispatch) => {
  try {
    dispatch(setIsAuth(false));
    dispatch(setCurrentUser({}));
    jsCookie.set('_nf_token', '');
    jsCookie.set('_firstName', '');
    jsCookie.set('_avatarUrl', '');
    jsCookie.set('_nf_username', '');
    if (process.browser) {
      localStorage.setItem('_nf_token', '');
      jsCookie.set('_nf_token', '');
    }
    window.location.replace('/');
  } catch (err) {
    // const { data } = err.response;
    dispatch(setIsAuth(false));
    dispatch(setCurrentUser({}));
  }
};

export const fetchUserProducts = () => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const { data } = await http.get('/user/products');
    dispatch(setUserProducts(data?.data));
  } catch (err) {
    // const { data } = err.response;
    dispatch(setCurrentUser({}));
    dispatch(setUserProducts([]));
  }
};
