/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Label, Input } from 'theme-ui';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { loginUserWithEmail } from 'reducers/auth/actions';
import PasswordInput from 'components/common/PasswordInput';
import Button from 'components/common/Button';

const initialValues = {
  email: '',
  password: '',
};

export default function Login({
  changeView,
  changeAllowSocial,
}: {
  changeView: (view: string) => void;
  changeAllowSocial: (state: boolean) => void;
}) {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const [values, setValues] = useState(initialValues);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    setSubmitting(false);
    changeAllowSocial(true);
  }, []);

  const handleChange = ({ target }) => {
    setValues({
      ...values,
      [target.name]: target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitting(true);
    loginUserWithEmail(values)(dispatch)
      .then((res) => {
        if (res?.status === 201) {
          setValues(initialValues);
          changeView('');
        }
        setSubmitting(false);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const canSubmit = () => {
    return values.email && values.password.length > 5;
  };

  return (
    <form onSubmit={handleSubmit}>
      <Label htmlFor="email">{t('Email')}</Label>
      <Input name="email" type="email" mb="3" onChange={handleChange} />

      <Label htmlFor="password">{t('Password')}</Label>
      <PasswordInput name="password" onChange={handleChange} />

      <div
        sx={{
          fontSize: '0.9rem',
          my: 2,
          display: 'flex',
          justifyContent: 'space-between',
          cursor: 'pointer',
        }}
      >
        <span onClick={() => changeView('Forgot Password')}>
          {t('Forgot Password?')}
        </span>
        <span onClick={() => changeView('Signup')}>{t('Sign up')}</span>
      </div>

      <Button
        disabled={!canSubmit}
        loading={submitting}
        type="button"
        onClick={handleSubmit}
        sx={{ width: '100%', mt: 2 }}
      >
        {t('Login')}
      </Button>
    </form>
  );
}
