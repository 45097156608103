/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setAuthModal, loginUser } from 'reducers/auth/actions';
import { selectAuth } from 'reducers/auth/selectors';
import { useTranslation } from 'react-i18next';
import { FcGoogle } from 'react-icons/fc';
import { ImSpinner2 } from 'react-icons/im';
import { GoogleLogin } from 'react-google-login';
import Modal from 'components/common/Modal';
import Login from './Login';
import Signup from './Signup';
import Button from 'components/common/Button';
import SpinnerCircle from 'components/common/SpinnerCircle';
import SignupSubmitted from './SignupSubmitted';

export default function AuthModal() {
  const { authModal, authSubmitting } = useSelector(selectAuth);
  const [submitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loadingGoodleAuth, setLoadingGoogleAuth] = useState(true);
  const [allowSocial, setAllowSocial] = useState(false);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    setTimeout(() => {
      setLoadingGoogleAuth(false);
    }, 1_000);
  }, []);

  useEffect(() => {
    if (authModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    setErrorMessage('');
    setSubmitting(false);
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [authModal]);

  const handleCloseModal = () => {
    setSubmitting(false);
    dispatch(setAuthModal(''));
    // if (localStorage.getItem('_nf_token')) dispatch(setAuthModal(''));
  };

  const changeAllowSocial = (stateAllow) => {
    setAllowSocial(!!stateAllow);
  };

  const onSuccessLogin = (response) => {
    const { tokenId } = response;
    loginUser({
      tokenId,
      type: authModal === 'Signup' ? 'signup' : 'login',
    })(dispatch)
      .then((res) => {
        if (res && res.token) {
          handleCloseModal();
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const onFailedLogin = ({ error }) => {
    if (error === 'popup_closed_by_user') {
      setErrorMessage(t('Login failed, please try again'));
    }
    setSubmitting(false);
  };

  const changeView = (view) => {
    dispatch(setAuthModal(view));
  };

  const renderViews = () => {
    switch (authModal) {
      case 'Login':
        return (
          <Login
            changeAllowSocial={changeAllowSocial}
            changeView={changeView}
          />
        );
      case 'Signup':
        return (
          <Signup
            changeAllowSocial={changeAllowSocial}
            changeView={changeView}
          />
        );
      case 'Signup Submitted':
        return <SignupSubmitted />;
      default:
        <Login changeAllowSocial={changeAllowSocial} changeView={changeView} />;
    }
  };

  return (
    <Modal
      open={!!authModal}
      onClose={handleCloseModal}
      contentLabel="Auth Modal"
      title={t(authModal)}
      width="350px"
    >
      {submitting && <SpinnerCircle />}

      {errorMessage ? <p>{errorMessage}</p> : null}

      {renderViews()}

      {authModal !== 'Signup Submitted' ? (
        <>
          <p sx={{ textAlign: 'center', my: 2 }}>{t('Or')}</p>

          <div
            sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}
          >
            <GoogleLogin
              clientId={process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID}
              onSuccess={onSuccessLogin}
              onFailure={onFailedLogin}
              cookiePolicy={'single_host_origin'}
              render={(renderProps) => (
                <Button
                  sx={{
                    mt: 2,
                    display: 'flex',
                    alignItems: 'center',
                    position: 'relative',
                    py: 1,
                    '&:disabled': {
                      cursor: 'not-allowed',
                    },
                  }}
                  variant="outline"
                  onClick={() => {
                    setSubmitting(true);
                    renderProps.onClick();
                  }}
                  disabled={
                    authSubmitting || renderProps.disabled || !allowSocial
                  }
                  loading={loadingGoodleAuth}
                >
                  <span
                    sx={{ display: 'inline-block', bg: 'white', mt: 1, mr: 2 }}
                  >
                    <FcGoogle size="1.3rem" />
                  </span>
                  {authModal === 'Login'
                    ? t('Login with Google')
                    : t('Signup with Google')}
                  {authSubmitting ? (
                    <div
                      sx={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        bg: 'rgba(0, 0, 0, 0.3)',
                      }}
                    >
                      <ImSpinner2 className="spinner" />
                    </div>
                  ) : null}
                </Button>
              )}
            />
          </div>
        </>
      ) : null}
    </Modal>
  );
}
