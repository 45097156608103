import * as types from './types';
import initialState from './initialState';

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case types.SET_LOADING:
      return {
        ...state,
        searchLoading: payload,
      };
    case types.SET_SUBMITTING:
      return {
        ...state,
        searchSubmitting: payload,
      };
    case types.SET_SEARCH_RESULTS:
      return {
        ...state,
        searchLoading: false,
        searchResults: payload?.searchResults || [],
      };
    default:
      return state;
  }
}
