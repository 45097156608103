/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Label, Input, Message, Checkbox } from 'theme-ui';
import { useTranslation } from 'react-i18next';
import PasswordInput from 'components/common/PasswordInput';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { signupUser } from 'reducers/auth/actions';
import Button from 'components/common/Button';
import { termsOfService, privacyPolicyLink } from 'constants/links';

const initialValues = {
  email: '',
  password: '',
  firstName: '',
  lastName: '',
};

export default function Signup({
  changeView,
  changeAllowSocial,
}: {
  changeView: (view: string) => void;
  changeAllowSocial: (state: boolean) => void;
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [values, setValues] = useState(initialValues);
  const [submitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState<{
    path?: string;
    message?: string;
  }>({});
  const [acceptedTermsAndConditions, setAcceptedTermsAndConditions] =
    useState(false);

  useEffect(() => {
    setSubmitting(false);
    changeAllowSocial(false);
    setAcceptedTermsAndConditions(false);
  }, []);

  const handleChange = ({ target }) => {
    setValues({
      ...values,
      [target.name]: target.value,
    });
  };

  const yupSchema = yup.object().shape({
    email: yup.string().email().required().label(t('Email')),
    firstName: yup.string().required().label(t('First Name')),
    lastName: yup.string().required().label(t('First Name')),
    password: yup.string().required().min(6).label(t('Password')),
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!acceptedTermsAndConditions) {
      setErrorMessage({
        path: '',
        message: t('You need to agree to our Terms of Service and Pricacy'),
      });
      return;
    }

    setErrorMessage({
      path: '',
      message: '',
    });

    yupSchema
      .validate({
        ...values,
      })
      .then(() => {
        setSubmitting(true);

        signupUser(values)(dispatch)
          .then((res) => {
            if (res?.status <= 201) {
              setValues(initialValues);
              changeView('Signup Submitted');
              setTimeout(() => {
                changeView('Login');
              }, 5_000);
            }
            setSubmitting(false);

            if (res?.status >= 400 && res.message) {
              setErrorMessage({
                path: '',
                message: res.message,
              });
            }
          })
          .finally(() => {
            setSubmitting(false);
          });
        setTimeout(() => setSubmitting(false), 60_000);
      })
      .catch(({ path, message }) => {
        setErrorMessage({ path, message });
      });
  };

  const handleTermsAndCondition = (e) => {
    const { checked } = e.target;
    setAcceptedTermsAndConditions(checked);
    changeAllowSocial(checked);
  };

  return (
    <form onSubmit={handleSubmit}>
      {errorMessage?.message ? (
        <Message variant="error" sx={{ mb: 3 }}>
          {errorMessage.message}
        </Message>
      ) : null}
      <Label htmlFor="firstName">{t('First Name')}</Label>
      <Input name="firstName" mb="2" onChange={handleChange} />

      <Label htmlFor="lastName">{t('Last Name')}</Label>
      <Input name="lastName" mb="2" onChange={handleChange} />

      <Label htmlFor="email">{t('Email')}</Label>
      <Input
        name="email"
        type="email"
        mb="2"
        onChange={handleChange}
        autoComplete="off"
      />

      <Label htmlFor="password">{t('Password')}</Label>
      <PasswordInput
        name="password"
        onChange={handleChange}
        autocomplete="off"
      />

      <Label sx={{ mt: 2 }} mb={3}>
        <Checkbox onChange={handleTermsAndCondition} />
        <div
          sx={{
            fontSize: 1,

            a: {
              textDecoration: 'none',
              color: 'primary',
            },
          }}
          dangerouslySetInnerHTML={{
            __html: t(
              'By clicking Sign up, you agree to our Terms of Service and Privacy Policy',
              {
                serviceLink: termsOfService,
                privacyLink: privacyPolicyLink,
              },
            ),
          }}
        />
      </Label>

      <div
        sx={{
          fontSize: '0.9rem',
          my: 2,
        }}
      >
        <span onClick={() => changeView('Forgot Password')}>
          {t('Already have an account?')}
        </span>
        <span
          sx={{ cursor: 'pointer', fontWeight: 600 }}
          onClick={() => changeView('Login')}
        >
          {' '}
          {t('Login')}
        </span>
      </div>

      <Button
        loading={submitting}
        type="button"
        onClick={handleSubmit}
        sx={{ width: '100%', mt: 2 }}
      >
        {t('Sign up')}
      </Button>
    </form>
  );
}
