import { combineReducers } from 'redux';
import currentUser from './currentUser';
import post from './post';
import postComment from './postComment';
import category from './category';
import author from './author';
import authorPopular from './authorPopular';
import auth from './auth';
import search from './search';
import chat from './chat';

const reducers = combineReducers({
  auth,
  currentUser,
  post,
  postComment,
  category,
  author,
  authorPopular,
  search,
  chat,
});

export default reducers;
